// ---------------------------------------
// RESOURCE EDIT WESTWIND.GLOBALIZATION
// ---------------------------------------
.resource-editor-icon, .resource-editor-icon:hover, .resource-editor-icon:visited {
    position: absolute;
    display: inline;
    height: 13px;
    width: 13px;
    text-decoration: none;
    z-index: 999999;
    opacity: 0.35;
    margin: -14px 0 0 -2px;
    cursor: pointer;
}

.resource-editor-icon {
    &:hover {
        opacity: 1;
    }

    &before {
        font-family: 'FontAwesome';
        content: "\f024"; /* flag */
        font-size: 9pt;
        color: red;
    }
}

.resource-editor-button {
    z-index: 999999;
    color: white;
    background-color: DarkGreen;
    opacity: 0.35;
    position: fixed;
    bottom: 10px;
    right: 20px;
    padding: 7px 9px 5px 10px;
    border-radius: 50%;

    &.off {
        background-color: #b64545;
    }

    &:before {
        font-family: 'FontAwesome';
        content: "\f024"; /* flag */
        font-size: 14pt;
    }

    &:hover {
        font-family: 'FontAwesome';
        opacity: 0.65;
    }
}

// ---------------------------------------
// ANGULAR TREEVIEW
// ---------------------------------------
/* Items kein overflow*/
.angular-ui-tree-node a {
    overflow-x: hidden;
}

/* angular tree view */
.tree-node {
    border: 1px solid #dae2ea;
    background: #f8faff;
    color: #7c9eb2;
}

.tree-node-content {
    margin: 2px;
}

.angular-ui-tree-handle {
    font-weight: normal !important;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// ---------------------------------------
// ANGULAR TEXT EDITOR
// ---------------------------------------
/*hide ng-cloak elements quicker*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* textAngular */
.ta-editor {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.ta-scroll-window > .ta-bind {
    min-height: 140px !important;
}

.ta-editor.ta-html, .ta-scroll-window.form-control {
    min-height: 140px !important;
}

[ta-button="ta-button"] {
    min-width: 40px !important;
}

#toolbarWC, #toolbarCC {
    float: right;
    display: inline-block;
}

.dev-sys-warning {
    background-color: red !important;
    color: white !important;
    padding: 8px;
}

.dev-sys-note {
    background-color: orange !important;
    color: white !important;
    padding: 8px;
}
