@use "@angular/material" as mat;
@use "sass:math";

@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~ag-grid-community/dist/styles/ag-theme-fresh.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~daterangepicker/daterangepicker.css";
@import "~textangular/dist/textAngular.css";
@import "~angular-busy/dist/angular-busy.css";
@import "~ui-select/dist/select.css";
@import "~pickadate/lib/themes/classic.time.css";
@import "~pickadate/lib/themes/classic.date.css";
@import "~pickadate/lib/themes/classic.css";
@import "~@claviska/jquery-minicolors/jquery.minicolors.css";

$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/v4-shims.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/light.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/css/devextreme.scss";
@import "~quill/dist/quill.snow.css";

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src:
        local("Roboto Italic"),
        local("Roboto-Italic"),
        url("assets/fonts/roboto-v20-latin-italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/roboto-v20-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src:
        local("Roboto"),
        local("Roboto-Regular"),
        url("assets/fonts/roboto-v20-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src:
        local("Roboto Medium"),
        local("Roboto-Medium"),
        url("assets/fonts/roboto-v20-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/roboto-v20-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

//*:not(.fa, .fal, .fas) {
//  font-weight: 300 !important;;
//}

@import "theme";

@include mat.core();

$bb-primary: mat.define-palette($mat-bb);
$bb-grey: mat.define-palette(mat.$grey-palette);
$bb-red: mat.define-palette(mat.$red-palette);
$bb-blue: mat.define-palette(mat.$light-blue-palette);
$bb-orange: mat.define-palette(mat.$orange-palette);
$bb-green: mat.define-palette(mat.$green-palette);
$bb-amber: mat.define-palette(mat.$amber-palette);
$bb-accent: mat.define-palette($mat-bb, A200, A100, A400);

$bb-warn: mat.define-palette(mat.$red-palette);

$bb-theme: mat.define-light-theme(
    (
        color: (
            primary: $bb-primary,
            accent: $bb-accent,
            warn: $bb-warn,
        ),
        typography:
            mat.define-typography-config(
                $button:
                    mat.define-typography-level(
                        $font-size: 14px,
                        $line-height: 36px,
                        $font-weight: 500,
                        $letter-spacing: normal,
                    ),
            ),
        density: 0,
    )
);

@include mat.all-component-themes($bb-theme);

@import "@mat-datetimepicker/core/datetimepicker/datetimepicker-theme";
@include mat-datetimepicker-theme($bb-theme);

html {
    --mat-card-title-text-size: 24px;
    --mat-card-title-text-tracking: 0;
    --mat-card-title-text-weight: 400;
    --mat-tab-header-divider-height: 1px;
    --mat-tab-header-divider-color: #e0e2ec;
}

mat-form-field {
    @include mat.form-field-density(-3);
}

bb-datetimepicker, bb-datepicker, bb-form-date-field {
        @include mat.datepicker-density(-3);
        @include mat.icon-button-density(-3);
}

mat-toolbar {
    mat-form-field {
        @include mat.form-field-density(-4);
        mat-icon {
            padding: 0 12px !important;
        }
    }

    &.mat-primary {
        mat-form-field {
            --mdc-outlined-text-field-input-text-color: #fff;
        }
    }
}

.mat-mdc-raised-button {
    border-radius: 2px !important;

    &:not(:disabled):hover {
        &:not([color]):not(.mat-primary) {
            background-color: #aaa !important;
        }

        &[color="primary"],
        &.mat-primary {
            background-color: #40df89 !important;
        }
    }
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary {
    --mdc-protected-button-label-text-color: #fff !important;
    --mdc-filled-button-label-text-color: #fff !important;
}

.mat-mdc-fab {
    box-shadow: none !important;
    border: 2px solid lightgray !important;
}

.content .mat-mdc-tab-header {
    margin-bottom: 8px;
}

.mat-mdc-card {
    padding: 24px !important;
}

bb-meta-card .mat-mdc-card {
    padding: 16px !important;
}

//router-outlet {
//padding-bottom: 32px;
//}

.container-fluid {
    margin-bottom: 32px;
}

mat-card mat-tab-header {
    margin: -24px -24px 24px -24px !important;
}

mat-card-title + mat-card-content mat-tab-header {
    margin-top: 0 !important;
}

bb-card mat-card mat-tab-header {
    margin-top: unset !important;
}

mat-dialog-content mat-tab-header {
    margin: auto -24px 24px -24px !important;
}

mat-card-content {
    padding: 0 !important;
    margin-bottom: 16px !important;
}

mat-card-title {
    margin-bottom: 8px !important;
}

mat-dialog-actions {
    padding: 0 24px 20px 24px !important;
}

.mat-mdc-dialog-surface {
    overflow: hidden;
}

.mdc-switch {
    margin-right: 8px !important;
}

.mat-mdc-card > .mat-mdc-list {
    margin: -16px;

    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
}

.mdc-linear-progress__buffer {
    background-color: #eee;
}

.sub-header-news {
    margin: 0 !important;
    padding: 4px 16px !important;
    height: auto !important;
    margin-top: 8px !important;
}

.branding-grey {
    background-color: rgb(117, 117, 117);
    color: rgb(158, 158, 158);
}

.support-active {
    color: rgba(255, 255, 255, 0.87);
    background-color: rgb(0, 191, 105);
}

.primary-card {
    background-color: rgb(0, 191, 105);
    color: rgba(255, 255, 255, 0.87);
}

.grey-500-600 {
    background-color: rgb(117, 117, 117);
    color: rgb(158, 158, 158);
}

.grey-800-A100 {
    color: #424242;
    background-color: #ffffff;
}

.color-block {
    z-index: 2;
    top: 0px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    position: absolute;
    vertical-align: middle;
    background-position: -80px 0;
    border: solid 1px #ccc;
    cursor: text;
    padding: 0;
    margin: 0;
    display: inline-block;
    margin-top: 10px;
}

.sidenav {
    transition: width cubic-bezier(0.18, 0.53, 0.17, 1) 125ms;
}

.sidenav .mat-toolbar {
    background: inherit;
}

.mat-toolbar.mat-primary {
    position: sticky;
    top: 0;
    z-index: 1;
}

.mat-mdc-icon-button,
.mat-mdc-button,
bb-nav-notifications {
    display: inline-flex !important;
}

.mat-mdc-button .mat-icon {
    margin-bottom: 12px !important;
}

bb-select,
bb-input,
bb-number-input,
bb-textarea {
    margin-top: 8px !important;
}

$left-nav-active: #cfcfcf;
.left-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0 !important;
    color: #5a5a5a;
    overflow: hidden;
    height: 100%;

    > div {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .parent-menu,
    .nav-child-menu div {
        :hover {
            cursor: pointer;
        }
    }

    mat-icon {
        color: rgba(0, 0, 0, 0.87) !important;
        text-align: center;
    }

    span {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .mdc-list-item--with-leading-icon .mdc-list-item__start {
        margin-right: 0 !important;
    }
    .spacing {
        height: 100%;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 64px;
        box-sizing: border-box;
        border: 4px solid transparent;
    }

    .active {
        background-color: $left-nav-active !important;
        .icon-wrapper {
            border-left: 4px solid map-get($mat-bb, 500);
        }
    }
}

.nav-child-menu {
    height: 0 !important;
    transition: height linear 200ms;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    overflow: hidden !important;
    will-change: height;
    width: 100%;

    div {
        height: 48px !important;
        display: flex;
        align-items: center;
    }

    span {
        font-size: 14px !important;
    }
}

.nav-child-menu,
.child-active {
    background-color: #f5f5f5;
}

.search-field {
    font-size: 14px;
}

.child-active,
.active {
    & + div > .nav-child-menu {
        @for $i from 1 through 10 {
            &.child-count-#{$i} {
                height: (48px * $i) !important;
            }
        }
    }
}

$body-padding: 16px;
main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;

    & > .content {
        box-sizing: border-box;
        padding: $body-padding;
    }
}

.mat-toolbar {
    min-height: 64px !important;

    .svg_logo {
        background: url("assets/images/logo/logo_padded.svg");
        background-size: cover;
        cursor: pointer;
        height: 64px;
        width: 64px;
        margin-right: 16px !important;
        margin-left: -14px;
        outline: none;
    }

    .mat-mdc-form-field {
        margin-top: 24px;
    }
}

.nav-search {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: #fff !important;
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: #fff !important;
    }
}

i.fa.fa-mr {
    margin-right: 8px;
}

a:not(.mat-mdc-button-base) {
    color: inherit !important;
    &:not(.underline) {
        text-decoration: none;
    }
}

.highlight-links a {
    color: map-get($mat-bb, 500) !important;
}

.mat-tab-labels,
md-tabs,
.mat-toolbar,
.mat-mdc-tab-nav-bar,
[mat-tab-nav-bar] {
    background-color: #fff;
}
.pull-out {
    width: calc(100% + #{$body-padding * 2});
    margin-left: -$body-padding;
    margin-right: -$body-padding;
    margin-top: -$body-padding;

    .mat-tab-labels,
    md-tabs,
    .mat-toolbar,
    .mat-mdc-tab-nav-bar,
    [mat-tab-nav-bar] {
        background-color: #fff;
    }

    .mat-mdc-tab-nav-bar {
        margin-bottom: $body-padding;
    }
    .mat-mdc-tab-nav-bar.dense {
        margin-bottom: 0;
    }

    .mat-toolbar:not(.dense) {
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
    }
}

ul.pull-out {
    margin-bottom: $body-padding !important;
}

iframe.mvc_holder {
    @extend .pull-out;
    margin: -$body-padding;
    border: 0;
    &.half {
        height: calc(50% + #{$body-padding * 2});
    }
    &:not(.half) {
        height: calc(100% + #{$body-padding * 2});
    }
}

.no-padding,
.no-padding mat-dialog-container {
    padding: 0 !important;
}

.cdk-overlay-pane {
    max-height: 100vh !important;
    max-width: 100vw;
}

$icon-font-size: 20px !important;
*:not([mat-icon-button]) .mat-icon:not(.mat-icon-inline) {
    font-size: $icon-font-size;
    line-height: 1.2em !important;
}

.mat-icon-button {
    margin-top: 16px;
}

.mat-badge-content i {
    line-height: unset !important;
}

.mat-mdc-menu-panel {
    max-width: 400px !important;
}

.mat-padding {
    padding: 8px;
}

.mat-mdc-list-item > .mat-mdc-checkbox {
    height: auto !important;
}

.mat-mdc-list-base .no-wrap.mat-mdc-list-item {
    margin: 0;
    height: unset !important;
    .mat-list-item__content {
        height: unset;
        padding: 0 4px;
    }

    .mat-line {
        overflow: unset;
        text-overflow: unset;
        white-space: pre-wrap;
    }
}

main > .content > * {
    & > .mat-mdc-tab-group {
        @extend .pull-out;

        .mat-mdc-tab-header {
            background-color: #fff;
        }
    }

    md-tabs,
    .md-nav-bar {
        background-color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    mat-sidenav[ng-reflect-opened="true"] {
        transform: translate3d(0px, 0px, 0px);
    }
}

.mat-drawer * {
    touch-action: auto !important;
}

.row-margin-bottom {
    margin-bottom: $body-padding;
    transition:
        opacity ease 0.1s,
        box-shadow ease 0.1s;

    .mat-mdc-card-title {
        font-weight: 300;
        margin-bottom: $body-padding;
    }
}

.reduce-row-margin-bottom {
    margin-bottom: -$body-padding !important;
}

.icon-avatar {
    background-color: #fff;
    font-size: 2em;
    color: #333;
    height: 2.2em;
    width: 2.2em;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;
    justify-content: center;
    flex-flow: row wrap;
    border-radius: 4px;
    transition: box-shadow ease 0.1s;

    &.active {
        background-color: map-get($mat-bb, 500);
        color: #fff;
    }
    .mat-icon {
        height: auto !important;
        width: auto !important;
    }
}

.clickable {
    cursor: pointer;
}

.padding-h {
    padding-left: $body-padding;
    padding-right: $body-padding;
}

.padding-v {
    padding-top: $body-padding;
    padding-bottom: $body-padding;
}

.padding {
    @extend .padding-v;
    @extend .padding-h;
}

.margin {
    margin: $body-padding;
}
.margin-half {
    margin: math.div($body-padding, 2);
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

body {
    font-family: "Roboto";
}

label {
    font-weight: inherit;
    margin: 0 !important;
}
.margin-top-nav-bar {
    margin-top: 80px !important;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-error {
    color: map-get($bb-red, 400) !important;
}

a.primary {
    color: map-get($mat-bb, 500) !important;

    &:hover {
        text-decoration: underline;
    }
}
.underline {
    text-decoration: underline;
}
.fill-primary {
    background-color: map-get($mat-bb, 500);
}
.fill-info {
    background-color: map-get($bb-blue, 400) !important;
}
.fill-warning {
    background-color: map-get($bb-orange, 400) !important;
}
.fill-error {
    background-color: map-get($bb-red, 400) !important;
}
.fill-info-light {
    background-color: map-get($bb-blue, 100);
}
.fill-warning-light {
    background-color: map-get($bb-orange, 100) !important;
}
.fill-error-light {
    background-color: map-get($bb-red, 100) !important;
}

.pricing-button {
    background-color: #009f49 !important;
    color: white !important;
    padding: 8px;
}

mw-form-builder {
    display: block;
    margin-top: 8px !important;
}

mw-form-builder .mat-mdc-form-field {
    width: 100%;
}

bb-form-flex-layout {
    .mat-mdc-unelevated-button {
        display: inline-block !important;
    }

    .mat-mdc-form-field-icon-suffix {
        padding-right: 16px;
    }
}

bb-form-table-collection > button > mat-icon {
    margin-right: 12px !important;
}

bb-shipping-weight-buttons {
    padding-top: 4px;
}

app-smtp-list {
    .mat-line {
        margin: 0 !important;
    }
    td:last-child {
        padding: 16px !important;
    }
}

[hidden] {
    display: none !important;
}

bb-form-text-field .mat-mdc-form-field-error-wrapper,
bb-form-text-area .mat-mdc-form-field-error-wrapper,
div[bb-form-select] .mat-mdc-form-field-error-wrapper {
    top: -24px !important;
}

div[mwformfield]:has(bb-form-text-field, bb-form-text-area, div[bb-form-select]) {
    margin-bottom: 0 !important;
}

.mat-tab-label,
[mat-tab-link] {
    text-transform: uppercase;
}

.table-select {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 20px;
}
.small-text {
    font-size: small;
}

.color-yellow {
    color: map-get($bb-amber, 400) !important;
}

.mat-mdc-card-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.mat-mdc-card-actions {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .mat-mdc-button,
    .mat-mdc-raised-button {
        margin: 0 8px !important;
    }
}

.mat-mdc-card-actions-align-end .mat-mdc-button:last-child,
.mat-mdc-card-actions-align-end .mat-mdc-raised-button:last-child {
    margin: 0 !important;
}

.mat-mdc-slide-toggle {
    margin-bottom: 8px;
}

.pointer {
    cursor: pointer;
}
.text-white {
    color: #fff;
}

mat-card[color="warn"] {
    @extend .fill-error, .text-white;
}

/** Icons im stepper werden nicht richtig angezeigt */
.mat-step-icon .mat-icon {
  height: 20px !important;
}

.pre {
    white-space: pre-wrap;
}

//Hide Tooltip when hovering the tooltip
.mat-mdc-tooltip-panel {
    pointer-events: none !important;
}

mat-icon.mat-icon.fal {
    overflow: visible;
}

.margin-fix {
    padding-top: 8px;
}

mat-divider.separator {
    margin: 16px 0;
}

.toolbar-divider {
    height: 32px;
}

table[mat-table] {
    background: none;

    th.mat-mdc-header-cell {
        background-color: map-get($bb-theme, "background", "card");
    }

    tbody {
        td.mat-mdc-cell {
            padding: 20px 10px 20px 0;
            background: map-get($bb-theme, "background", "background");
            background: linear-gradient(
                180deg,
                rgba(map-get($bb-theme, "background", "background"), 0) 0%,
                rgba(map-get($bb-theme, "background", "background"), 1) 5%,
                rgba(map-get($bb-theme, "background", "background"), 1) 6%
            );

            &:first-of-type {
                padding-left: 0;
            }
        }
    }
}
