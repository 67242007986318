.ag-theme-material {

    .ag-icon-checkbox-checked:empty {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMyMUM3N0MiLz48L3N2Zz4=) !important;
    }

    .ag-cell-focus {
        border: 1px solid transparent !important;
    }

    .ag-cell, .ag-header-cell {
        padding-left: 8px !important;
        padding-right: 2px !important;

        .ag-header-cell-label {
            width: calc(100% - 8px);

            .ag-header-icon {
                margin-left: 0;
            }
        }
    }

    .ag-header-cell-resize:after {
        border-right: 1px solid #e0e0e0;
        display: block;
        height: 24px;
        margin-top: 16px;
        content: "resize";
        overflow: hidden;
        text-indent: 8px;
        width: 8px;
    }
} 
    .gridBillbee {
        height: 200px;

        @media (min-height: 450px) {
            height: 350px;
        }

        @media (min-height: 500px) {
            height: 300px;
        }

        @media (min-height: 550px) {
            height: 350px;
        }

        @media (min-height: 600px) {
            height: 400px;
        }

        @media (min-height: 650px) {
            height: 450px;
        }

        @media (min-height: 700px) {
            height: 500px;
        }

        @media (min-height: 750px) {
            height: 550px;
        }

        @media (min-height: 800px) {
            height: 600px;
        }

        @media (min-height: 850px) {
            height: 650px;
        }

        @media (min-height: 900px) {
            height: 700px;
        }

        @media (min-height: 950px) {
            height: 750px;
        }

        @media (min-height: 1000px) {
            height: 800px;
        }

        @media (min-height: 1050px) {
            height: 850px;
        }

        @media (min-height: 1100px) {
            height: 900px;
        }

        @media (min-height: 1150px) {
            height: 950px;
        }

        @media (min-height: 1200px) {
            height: 1000px;
        }

        @media (min-height: 1250px) {
            height: 1050px;
        }

        @media (min-height: 1300px) {
            height: 1100px;
        }

        @media (min-height: 1350px) {
            height: 1150px;
        }

        @media (min-height: 1400px) {
            height: 1200px;
        }

        @media (min-height: 1450px) {
            height: 1250px;
        }

        @media (min-height: 1500px) {
            height: 1300px;
        }

        @media (min-height: 1550px) {
            height: 1350px;
        }

        @media (min-height: 1600px) {
            height: 1400px;
        }

        @media (min-height: 1650px) {
            height: 1450px;
        }

        @media (min-height: 1700px) {
            height: 1500px;
        }

        @media (min-height: 1750px) {
            height: 1550px;
        }

        @media (min-height: 1800px) {
            height: 1600px;
        }

        @media (min-height: 1850px) {
            height: 1650px;
        }

        @media (min-height: 1900px) {
            height: 1700px;
        }

        @media (min-height: 1950px) {
            height: 1750px;
        }

        @media (min-height: 2000px) {
            height: 1800px;
        }

        @media (min-height: 2050px) {
            height: 1850px;
        }

        @media (min-height: 2100px) {
            height: 1900px;
        }

        @media (min-height: 2150px) {
            height: 1950px;
        }

        @media (min-height: 2200px) {
            height: 2000px;
        }

        @media (min-height: 2250px) {
            height: 2050px;
        }

        @media (min-height: 2300px) {
            height: 2100px;
        }

        @media (min-height: 2350px) {
            height: 2150px;
        }

        @media (min-height: 2400px) {
            height: 2200px;
        }

        @media (min-height: 2450px) {
            height: 2250px;
        }

        @media (min-height: 2500px) {
            height: 2300px;
        }
        /*
    *[class*="ag-"] {
        border-color: #fff !important;
    }*/
        .ag-header, .ag-header-container {
            background: #ddd !important;
        }

        .ag-header-cell-text {
            display: inline-block;
            margin: 0 auto;
            font-weight: bold;
        }

        .ag-row-even {
            background-color: #f6f6f6 !important;
        }

        .ag-row-odd {
            background-color: #f0f0f0 !important;
        }
    }

    .ag-img-col {
        padding: 4px;
    }

    .grid-cell-rightalign {
        text-align: right;
        padding-right: 5px;
    }

    .ag-row-even {
        background-color: #fafafa !important;
    }

    .ag-row * {
        -webkit-touch-callout: default !important; /* iOS Safari */
        -webkit-user-select: text !important;
        ; /* Safari */
        -khtml-user-select: text !important;
        ; /* Konqueror HTML */
        -moz-user-select: text !important;
        ; /* Firefox */
        -ms-user-select: text !important;
        ; /* Internet Explorer/Edge */
        user-select: text !important;
        ;
        /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
    }

    .bb-row-warning-bg {
        background-color: rgba(255, 0, 0, 0.1)!important;
    }

    .ag-editable:not(.ag-cell-inline-editing):hover:after {
        font-family: 'Font Awesome 6 Pro';
        float: right;
        content: "\f044";
        font-size: 1.2em;
        font-weight: 300;
        font-style: normal;
        margin-top: 4px;
    }
