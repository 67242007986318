md-toast {
    .md-toast-content md-content {
        background-color: transparent;
        padding: 6px;

        a,a:visited,a:active,a:hover {
            color: white;
        }
    }

    @alpha: 0.90;

    &.md-success-toast-theme .md-toast-content {
        background-color: rgba(0, 191, 105, @alpha);
    }

    &.md-info-toast-theme .md-toast-content {
        background-color: rgba(221, 221, 221, @alpha);
    }

    &.md-error-toast-theme .md-toast-content {
        background-color: rgba(255, 69, 69, @alpha);
    }

    &.md-warning-toast-theme .md-toast-content {
        background-color: rgba(255, 144, 13, @alpha);
    }
}

md-toast .md-toast-content {
    padding: 0 2px;
}