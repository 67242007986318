.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMyMUM3N0MiLz48L3N2Zz4=) !important;
}
.ag-theme-material .ag-cell-focus {
  border: 1px solid transparent !important;
}
.ag-theme-material .ag-cell,
.ag-theme-material .ag-header-cell {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
.ag-theme-material .ag-cell .ag-header-cell-label,
.ag-theme-material .ag-header-cell .ag-header-cell-label {
  width: calc(100% - 8px);
}
.ag-theme-material .ag-cell .ag-header-cell-label .ag-header-icon,
.ag-theme-material .ag-header-cell .ag-header-cell-label .ag-header-icon {
  margin-left: 0;
}
.ag-theme-material .ag-header-cell-resize:after {
  border-right: 1px solid #e0e0e0;
  display: block;
  height: 24px;
  margin-top: 16px;
  content: "resize";
  overflow: hidden;
  text-indent: 8px;
  width: 8px;
}
.gridBillbee {
  height: 200px;
  /*
    *[class*="ag-"] {
        border-color: #fff !important;
    }*/
}
@media (min-height: 450px) {
  .gridBillbee {
    height: 350px;
  }
}
@media (min-height: 500px) {
  .gridBillbee {
    height: 300px;
  }
}
@media (min-height: 550px) {
  .gridBillbee {
    height: 350px;
  }
}
@media (min-height: 600px) {
  .gridBillbee {
    height: 400px;
  }
}
@media (min-height: 650px) {
  .gridBillbee {
    height: 450px;
  }
}
@media (min-height: 700px) {
  .gridBillbee {
    height: 500px;
  }
}
@media (min-height: 750px) {
  .gridBillbee {
    height: 550px;
  }
}
@media (min-height: 800px) {
  .gridBillbee {
    height: 600px;
  }
}
@media (min-height: 850px) {
  .gridBillbee {
    height: 650px;
  }
}
@media (min-height: 900px) {
  .gridBillbee {
    height: 700px;
  }
}
@media (min-height: 950px) {
  .gridBillbee {
    height: 750px;
  }
}
@media (min-height: 1000px) {
  .gridBillbee {
    height: 800px;
  }
}
@media (min-height: 1050px) {
  .gridBillbee {
    height: 850px;
  }
}
@media (min-height: 1100px) {
  .gridBillbee {
    height: 900px;
  }
}
@media (min-height: 1150px) {
  .gridBillbee {
    height: 950px;
  }
}
@media (min-height: 1200px) {
  .gridBillbee {
    height: 1000px;
  }
}
@media (min-height: 1250px) {
  .gridBillbee {
    height: 1050px;
  }
}
@media (min-height: 1300px) {
  .gridBillbee {
    height: 1100px;
  }
}
@media (min-height: 1350px) {
  .gridBillbee {
    height: 1150px;
  }
}
@media (min-height: 1400px) {
  .gridBillbee {
    height: 1200px;
  }
}
@media (min-height: 1450px) {
  .gridBillbee {
    height: 1250px;
  }
}
@media (min-height: 1500px) {
  .gridBillbee {
    height: 1300px;
  }
}
@media (min-height: 1550px) {
  .gridBillbee {
    height: 1350px;
  }
}
@media (min-height: 1600px) {
  .gridBillbee {
    height: 1400px;
  }
}
@media (min-height: 1650px) {
  .gridBillbee {
    height: 1450px;
  }
}
@media (min-height: 1700px) {
  .gridBillbee {
    height: 1500px;
  }
}
@media (min-height: 1750px) {
  .gridBillbee {
    height: 1550px;
  }
}
@media (min-height: 1800px) {
  .gridBillbee {
    height: 1600px;
  }
}
@media (min-height: 1850px) {
  .gridBillbee {
    height: 1650px;
  }
}
@media (min-height: 1900px) {
  .gridBillbee {
    height: 1700px;
  }
}
@media (min-height: 1950px) {
  .gridBillbee {
    height: 1750px;
  }
}
@media (min-height: 2000px) {
  .gridBillbee {
    height: 1800px;
  }
}
@media (min-height: 2050px) {
  .gridBillbee {
    height: 1850px;
  }
}
@media (min-height: 2100px) {
  .gridBillbee {
    height: 1900px;
  }
}
@media (min-height: 2150px) {
  .gridBillbee {
    height: 1950px;
  }
}
@media (min-height: 2200px) {
  .gridBillbee {
    height: 2000px;
  }
}
@media (min-height: 2250px) {
  .gridBillbee {
    height: 2050px;
  }
}
@media (min-height: 2300px) {
  .gridBillbee {
    height: 2100px;
  }
}
@media (min-height: 2350px) {
  .gridBillbee {
    height: 2150px;
  }
}
@media (min-height: 2400px) {
  .gridBillbee {
    height: 2200px;
  }
}
@media (min-height: 2450px) {
  .gridBillbee {
    height: 2250px;
  }
}
@media (min-height: 2500px) {
  .gridBillbee {
    height: 2300px;
  }
}
.gridBillbee .ag-header,
.gridBillbee .ag-header-container {
  background: #ddd !important;
}
.gridBillbee .ag-header-cell-text {
  display: inline-block;
  margin: 0 auto;
  font-weight: bold;
}
.gridBillbee .ag-row-even {
  background-color: #f6f6f6 !important;
}
.gridBillbee .ag-row-odd {
  background-color: #f0f0f0 !important;
}
.ag-img-col {
  padding: 4px;
}
.grid-cell-rightalign {
  text-align: right;
  padding-right: 5px;
}
.ag-row-even {
  background-color: #fafafa !important;
}
.ag-row * {
  -webkit-touch-callout: default !important;
  /* iOS Safari */
  -webkit-user-select: text !important;
  /* Safari */
  -khtml-user-select: text !important;
  /* Konqueror HTML */
  -moz-user-select: text !important;
  /* Firefox */
  -ms-user-select: text !important;
  /* Internet Explorer/Edge */
  user-select: text !important;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}
.bb-row-warning-bg {
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.ag-editable:not(.ag-cell-inline-editing):hover:after {
  font-family: 'Font Awesome 6 Pro';
  float: right;
  content: "\f044";
  font-size: 1.2em;
  font-weight: 300;
  font-style: normal;
  margin-top: 4px;
}
/* Validation Errors*/
.validation-summary-errors {
  font-weight: bold;
}
.validation-summary-errors ul {
  list-style-type: none;
}
/* Tabheader hervorheben, wenn das Tab einen Validationfehler enthält */
.tabHeaderValidationError {
  color: #CF003E !important;
  font-weight: bold;
}
/* ShowErrors validation: Message ohne error ausblenden*/
.form-group .help-block {
  display: none;
}
.form-group.has-error .help-block {
  display: block;
}
.form-control-alert {
  color: #660000 !important;
}
.label-auto {
  background-color: #3399ff;
}
.label-alert {
  background-color: #ff8888;
}
/* ui bootstrap datepicker */
.datepicker + ul {
  padding: 6px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #00C069;
  color: #fff;
  cursor: pointer;
}
.tab-content {
  margin-top: 10px;
}
/* Forms kleiner */
.form-group {
  margin-bottom: 10px;
}
/* Pagination ui-bootstrap*/
.pagination {
  /* Pagination gleich hoch machen */
}
.pagination > .active > a,
.pagination > .active .pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #CF003E;
  border-color: #CF003E;
}
.pagination > li > a {
  color: #888;
  text-decoration: none;
}
.pagination > li > a,
.pagination > li > a > span {
  padding: 3px 8px;
}
.pagination ul > li > a {
  height: 20px;
}
.navbar {
  height: 40px;
  min-height: 40px;
}
.navbar-brand {
  height: 40px;
}
.navbar-form {
  padding: 0 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.btn-danger {
  background-color: #CF003E;
  background-image: -moz-linear-gradient(top, #FF5C85, #FF1144);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#FF5C85), to(#FF1144));
  background-image: -webkit-linear-gradient(top, #FF5C85, #FF1144);
  background-image: -o-linear-gradient(top, #FF5C85, #FF1144);
  background-image: linear-gradient(to bottom, #FF5C85, #FF1144);
}
.btn-danger:active {
  background-color: #FF1144;
  color: #ffffff;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #FF1144;
}
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background-color: #FF1144;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #CF003E;
}
.btn-success {
  background-color: #00a75b;
  border: 1px solid #999;
}
.btn-success:active {
  color: #ffffff;
  background-color: #00c069;
}
.btn-success:active:focus {
  background-color: #00c069;
}
.btn-success:focus {
  background-color: #00c069;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #00c069;
  *background-color: #00c069;
}
.btn-success.open {
  background-color: #00a75b;
  border: 1px solid #999;
}
.btn-success.active {
  color: #ffffff;
  background-color: #00c069;
}
.btn-success.active:focus {
  background-color: #00c069;
}
.btn-success.focus {
  background-color: #00c069;
}
.btn-success.disabled,
.btn-success[disabled] {
  color: #ffffff;
  background-color: #00c069;
}
.alert {
  background-color: #d0e9c6;
  border: 1px solid #d0e9c6;
  color: #333;
}
.alert.alert-success {
  background-color: #d0e9c6;
  border-color: #d0e9c6;
  color: #333;
}
.alert.alert-error {
  background-color: #FFADC2;
  border-color: #FFADC2;
  color: #333;
}
.alert.alert-info {
  background-color: #d0e9c6;
  border-color: #d0e9c6;
  color: #333;
}
.nav-tabs li a {
  cursor: pointer;
}
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 550px;
}
.navbar .nav > li > a {
  padding: 10px;
}
/*
DROPDOWN ON HOVER
*/
ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;
}
a.menu:after,
.dropdown-toggle:after {
  content: none;
}
/*
LEGENDS + FIELDSETS IN DROPDOWNS
*/
ul.dropdown-menu form {
  margin: 0 0 0;
}
ul.dropdown-menu fieldset {
  margin: 0.5em 1em 1em 1em;
  padding: 0.5em;
  border: 1px solid #ccc;
}
ul.dropdown-menu legend {
  width: auto;
  padding: 0.5em;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 0;
  color: #000;
  border: 0;
  font-weight: bold;
}
ul.dropdown-menu label {
  color: #000;
}
div.dropdown-menu {
  padding: 10px 10px 0;
}
div.dropdown-menu input.remember {
  float: left;
  margin-right: 10px;
}
/* blendet die legend bei Tab-Nav aus, da redundant, aber für Screenreader bleibt es sichtbar */
div.tab-pane legend {
  text-indent: -999em;
  height: 0;
  border: 0;
}
/* Popovers werden sonst abgeschnitten, wenn sie den Rand des Tabs überchreiten */
.tab-content {
  overflow: initial;
}
.colorpicker {
  z-index: 1061;
}
.label-important,
.badge-important {
  background-color: #f55;
}
.label-success,
.badge-success {
  background-color: #d0e9c6;
}
.label-success-dark,
.badge-success-dark {
  background-color: #00C069;
}
.table tbody tr.warning td {
  background-color: #FFD6E0;
}
.table.table-hover tbody tr.warning:hover td {
  background-color: #FFADC2;
}
.success,
.table tbody tr.success td,
.datagrid .group.success .col {
  background-color: #d0e9c6;
}
.table-hover tbody tr.success:hover td {
  background-color: #d0e9c6;
}
.table tbody tr.green td {
  background-color: #9feeac;
}
.table tbody tr.error td {
  background-color: #FF2969;
}
.has-error .form-control {
  border-color: #CF003E;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #CF003E;
}
.control-group.error > label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #CF003E;
}
.form-item-error {
  font-size: 12px;
  padding: 6px 0;
  color: red;
}
.ui-select-search.form-control {
  padding: 0 0 0 10px;
}
.ui-select-bootstrap .ui-select-toggle > a.btn {
  right: 24px;
}
html,
body {
  height: 100%;
  background-color: unset;
}
body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  /* padding-top: 30px; */
  /* padding-bottom: 40px; */
}
body.start .thumbnail {
  padding-top: 20px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: #999 0 0 9px;
  -moz-box-shadow: #999 0 0 9px;
  box-shadow: #999 0 0 9px;
  color: #666;
}
body.start .thumbnail .fa-group,
body.start .thumbnail .fa-cogs,
body.start .thumbnail .fa-money {
  font-size: 2em;
  color: #CF003E;
}
body.start #youtube {
  padding-top: 0;
  border-color: #000;
  background-color: #000;
}
body.start #youtube iframe {
  border: 0;
  max-width: 100%;
}
body.start .caption {
  padding: 0 15px 10px 15px;
}
body.start article,
body.start .article {
  margin-top: 30px;
  margin-bottom: 90px;
  padding: 10px 20px;
}
body.background {
  padding-top: 0;
  padding-bottom: 0;
}
body.background header {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 10px;
  height: 100px;
  width: 100%;
  z-index: 1;
}
body.background #logoLink {
  right: 50px;
  top: 15px;
  position: fixed;
  z-index: 1;
}
body.background .navbar {
  padding-top: 35px;
  padding-bottom: 10px;
  max-width: 700px;
  margin-left: 50px;
}
body.background #mainContent {
  margin-top: 130px;
}
/* very small form */
form.form-tiny .form-group {
  margin-bottom: 4px;
}
form.form-tiny .form-group .checkbox {
  margin-bottom: 4px;
  margin-top: 4px;
}
form.form-tiny fieldset legend {
  margin-bottom: 4px;
  font-size: 18px;
}
footer {
  font-size: 85%;
  width: 98%;
  padding-top: 10px;
  margin: 20px auto 0 auto;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  color: #777;
  overflow-x: hidden;
}
footer a {
  color: #777 !important;
}
a {
  color: #009f49;
  text-decoration: none;
}
a:hover {
  color: #009f49;
  text-decoration: underline;
}
label {
  font-weight: normal;
}
/* In Tabellen sitzt die Auswahlcheckbox sonst zu tief */
tbody input[type="checkbox"] {
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
h1 {
  font-weight: 300;
  font-style: normal;
}
.table thead th {
  color: #111;
  background-color: #f6f6f6;
  font-weight: normal;
  white-space: nowrap;
}
.table thead th .sortlink {
  cursor: pointer;
}
.table td p {
  padding-top: 8px;
}
/* bei Inputs in kleinen Tabellen das Riesenmargin entfernen */
.table-condensed input,
.table-condensed select,
.table-condensed .input-append {
  margin-bottom: 0;
}
.diffHighlightPositive {
  background-color: #ECFFF6;
}
.diffHighlightNegative {
  background-color: #FFADC2;
}
.partialPayment {
  background-color: #FFe2a2;
}
/* UI SELECT for Logo Selection */
.logo-select .ui-select-toggle {
  height: 60px;
}
.logo-select img {
  max-height: 50px;
}
/* Display of fields in a address panel */
.addr-panel-field {
  background-color: #e5e5e5;
  margin-right: 2px;
  padding: 1px 2px;
}
.shipping-svc-panel {
  margin-bottom: 3px;
}
.shipping-svc-panel .panel-heading {
  padding: 4px 10px;
}
.shipping-svc-panel .panel-body {
  padding: 4px 10px;
}
.shipping-svc-panel-alert {
  background-color: #ffbbbb;
}
.shipping-svc-panel-alert .panel-heading {
  background-color: #ff8888;
}
.shipping-svc-panel-auto {
  background-color: #99ccff;
}
.shipping-svc-panel-auto .panel-heading {
  background-color: #3399ff;
}
/* icons in text boxen */
i.form-control-feedback {
  right: 40px;
  top: 9px;
  color: #00C069;
  font-size: 16px;
  pointer-events: initial;
  opacity: 0.6;
  height: auto;
  z-index: 0;
}
/* icons neben checkboxen*/
i.form-control-feedback-checkbox {
  color: #00C069;
  position: relative;
  font-size: 16px;
  pointer-events: initial;
  opacity: 0.6;
  height: auto;
}
button i.form-control-feedback {
  right: 2px;
  top: 2px;
  color: #00C069;
  width: 12px;
  font-size: 12px;
  pointer-events: initial;
  opacity: 0.6;
  height: auto;
}
/* icons in infoboxen / Fehlermeldungen groß und verital zentriert*/
.icon-msg-box {
  font-size: 30px;
  padding-right: 10px;
  vertical-align: middle;
}
/* inline Checkbox in BulkEdit Formularen*/
input[type="checkbox"].checkbox-bulkedit {
  margin-top: 10px;
}
/* inline edit, damit der edit Button auch bei leeren Feldern positioniert wird*/
.inline-edit-container {
  position: relative;
  height: 100%;
}
#ajaxLoaderNavAngular {
  color: #00C069;
}
li.activeNav {
  border-bottom: 3px solid #00C069;
  height: 39px;
}
.btn-tiny {
  font-size: 8px;
  height: 16px;
  padding: 2px 5px;
  border-radius: 3px;
}
.progress-billbee .bar,
.progress .bar-success {
  background-color: #9DBB1A;
  background-image: -moz-linear-gradient(top, #00C069, #00C069);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9DBB1A), to(#CEE07F));
  background-image: -webkit-linear-gradient(top, #9DBB1A, #CEE07F);
  background-image: -o-linear-gradient(top, #9DBB1A, #CEE07F);
  background-image: linear-gradient(to bottom, #9DBB1A, #CEE07F);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9DBB1A', endColorstr='#CEE07F', GradientType=0);
}
.progress-billbee.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #00C069;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
#dialogContent,
.dialog {
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.ruleContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-bottom: 5px;
  background-color: #EFF5D4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: solid 1px #009f49;
}
.editLinearTabContainer {
  /* von Order.Edit imt Linear Modus benutzt */
}
.editLinearTabContainer h4 {
  background-color: #ECFFF6;
  font-weight: bold;
  padding: 4px;
}
.editLinearTabContainer > div.tab-pane {
  padding: 0 10px 5px 10px;
  margin-bottom: 10px;
  background-color: #EFF5D4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: solid 1px #00C069;
}
.ulArrow,
.ulCheck {
  list-style: none;
  margin-left: 15px;
}
.ulArrow li:before {
  font-family: 'FontAwesome', serif;
  content: '\f101';
  margin: 0 5px 0 -15px;
  color: #CF003E;
}
.ulCheck li:before {
  font-family: 'FontAwesome', serif;
  content: '\f046';
  margin: 0 5px 0 -15px;
  color: #00C069;
}
/* Order.Edit etwas sparsamer mit dem Platz umgehen */
#details.tab-pane td {
  padding: 2px;
}
#details.tab-pane .input-append .add-on:last-child,
#details.tab-pane .input-append .btn:last-child,
#details.tab-pane input,
#details.tab-pane select,
#details.tab-pane .combobox-container .add-on {
  padding-top: 2px;
  padding-bottom: 2px;
}
/* Ansicht dropdown Seite Bestellungen: Checkboxen etwas stauchen */
#visibleColumns label.checkbox {
  margin-bottom: 0;
}
a.historyLink {
  text-decoration: none;
  color: black;
}
a.historyLink:hover {
  color: black;
}
/* Regios fontPicker: Dropdown über input boxen legen*/
.fontbox {
  z-index: 1;
}
.centeredWithHorizontalLine {
  width: 100%;
  display: inline-block;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.centeredWithHorizontalLineText {
  padding: 0 10px;
  background-color: white;
  color: black;
}
.orderGridBody ul {
  margin-left: 0;
}
.orderGridBody ul li {
  list-style: none;
}
.orderGridBody ul li i {
  color: #ccc;
  font-size: 60%;
  vertical-align: middle;
}
/* Kleines grünes Hilfe Icon in Dialogen neben dem Close x*/
.titleHelpIcon {
  color: #00C069;
  margin-right: 4px;
  -o-transition: color 0.1s ease-out, background 1s ease-in;
  -ms-transition: color 0.1s ease-out, background 1s ease-in;
  -moz-transition: color 0.1s ease-out, background 1s ease-in;
  -webkit-transition: color 0.1s ease-out, background 1s ease-in;
  transition: color 0.1s ease-out, background 1s ease-in;
  font-size: 200%;
  position: absolute;
  right: 10px;
  top: 45px;
}
.titleHelpIcon:hover {
  color: #40df89;
  text-decoration: none;
}
/* inline edit elemente mit pos=absolute über die Zelle rendern
   Parent muss pos=relative haben. Bei Firefox klappts bei TD aber nicht
   Deshalb muss das Input mit einem pos=rel div gewrappt werden
*/
.inlineeditInput {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
}
/* Order.ArticleInfo */
td.articleData i.fa-info-circle:hover {
  cursor: pointer;
}
.paragraph {
  margin-bottom: 10px;
}
.colorError {
  color: #f55;
}
.colorSuccess {
  color: #00C069;
}
/* Highlighting von Unterschieden bei Artikelimport*/
/* Anzeige der StatusIcons bei Order */
i.status-icon-disabled {
  color: #ddd;
}
i.status-icon-enabled {
  color: #00C069;
}
/* Große Schaltflächen mit Überschrift und Icon (z.B. auf der Seite Einstellungen) */
button.bigButtonLink {
  text-decoration: none;
  color: inherit;
  box-shadow: #999 0 0 9px;
  text-align: left;
  min-height: 150px !important;
  padding: 15px;
  margin-top: 10px;
  white-space: normal;
}
/* Make the modal face animation a bit faster */
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -ms-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
/* Suchfeld */
input.search-query {
  width: 120px;
}
.filter-close {
  float: none;
  position: relative;
  left: 145px;
  top: 7px;
  z-index: 3;
  width: 10px;
  height: 10px;
}
.debug {
  background: #ffff00;
}
.debug2 {
  background: #ff0000;
}
/* Tooltips in Modals nach oben holen*/
.tooltip {
  z-index: 2000 !important;
}
/* popover etwas breiter machen */
.popover {
  width: 280px;
  z-index: 2000 !important;
}
.popover table {
  font-size: 80%;
  margin-bottom: 2px;
}
#notificationMenu {
  font-weight: 300;
}
#notificationMenu:hover {
  font-weight: 700;
}
/* inline edit / info */
td.inlineedit-highlight,
tr.inlineedit-highlight td,
td.inlineinfo-highlight,
tr.inlininfo-highlight td {
  background-color: #00C069 !important;
}
/* typeahead in modals */
.typeahead {
  z-index: 1100;
}
/* affix */
#affixFixed.affix {
  top: 40px;
  width: 100%;
  background-color: white;
  z-index: 1;
}
.affix #pageSizeInfo {
  margin-right: 40px;
}
#actionbuttons {
  margin-bottom: 10px;
}
div#actionbuttons i {
  font-size: 16px;
}
a.sortlink {
  cursor: pointer;
}
/* Bilderanzeige Order.Edit */
span.detailImg {
  position: relative;
}
.detailImgZoom {
  position: absolute;
  max-width: inherit;
  z-index: 1000;
}
.pickadate-modal {
  position: fixed !important;
}
.setup-item-status .fa {
  padding: 0 !important;
  font-size: 19px !important;
}
.setup-item-status .fa-check {
  color: #00C069 !important;
}
.fullwidth {
  width: 100% !important;
}
.fa-expand {
  cursor: pointer;
}
.tab-pane-section {
  padding: 0 25px;
}
.stockName {
  background-color: #eee;
  padding: 4px;
  text-align: center;
  font-weight: bold;
}
i.primary {
  color: #00C069;
}
i.fa-1_5x {
  font-size: 1.5em;
}
bb-split-button {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  margin-right: 8px !important;
  margin-left: 8px !important;
  border-radius: 2px;
}
bb-split-button .md-button {
  box-shadow: none !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 0;
}
bb-split-button .md-button:not(:first-child),
bb-split-button md-menu > .md-button {
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.bb-has-counter {
  overflow: visible;
}
.bb-counter {
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 17px;
  border: 1px solid #00a75b;
  color: #fff;
  height: 17px;
  z-index: 999;
  font-size: 11px;
  font-weight: bold;
  vertical-align: top;
  line-height: 9px;
  border-radius: 10px;
  padding: 3px;
  background: #00C069;
}
.ui-select-bootstrap .ui-select-toggle .btn {
  right: 29px !important;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  right: 100%;
  margin-top: -1px;
}
.ta-editor {
  width: 100%;
}
.drag-drop-list-item {
  cursor: move;
  min-height: 60px;
  height: auto !important;
  background: white;
  overflow: hidden;
  display: block;
}
.drag-drop-list-item .mat-list-item-content {
  border-bottom: 1px solid #ddd !important;
  padding: 10px 16px !important;
  height: auto !important;
}
/* BELL */
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  70% {
    transform: rotate(15deg);
  }
  90% {
    transform: rotate(0deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.mat-toolbar .fa-bell-on,
.loading-indicator .fa-bell-on,
.mat-toolbar .fa-bell-exclamation,
.loading-indicator .fa-bell-exclamation {
  animation: ring 1s ease 1;
  transform-origin: center top;
}
.mat-toolbar .fa-sync-alt,
.loading-indicator .fa-sync-alt {
  animation: rotate 2s ease infinite;
  width: 1.25em;
  height: 1.25em;
  text-align: center;
}
/* for checkboxes in amazon shop tpl */
.checkbox-margin-right {
  margin-right: 20px !important;
}
/* Ansicht: Versandstation */
.cardTitleWithActionIcon {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}
.cardTitleWithActionIcon > .fa-close {
  margin: 0;
  cursor: pointer;
}
md-slider-container md-input-container input[type="number"] {
  min-width: 55px;
}
.md-progress-linear-absolute {
  position: absolute;
  z-index: 1000;
}
.bg-color-error {
  background-color: #f55 !important;
}
.aligned-content {
  margin: 0 -8px;
}
md-button.groupX {
  font-size: 16px;
  margin: 20px 0 !important;
  padding: 3px 15px 3px 15px !important;
  color: #312e2e;
  background-color: rgba(224, 224, 224, 0.96);
  text-transform: none;
  font-weight: 400;
  min-width: 100px;
}
md-button.middle {
  border-radius: 0;
  border-left: 1px solid rgba(230, 230, 230, 0.96);
  border-right: 1px solid rgba(230, 230, 230, 0.96);
}
md-button:not([disabled]):hover {
  background-color: rgba(193, 193, 193, 0.96);
  color: rgba(44, 65, 164, 0.96);
  transition: 0.3s;
}
.bb-grey-toolbar {
  background-color: #eee !important;
  color: #333 !important;
}
.bb-white-toolbar {
  background-color: #fff !important;
  color: #333 !important;
}
.md-icon-button i.fa {
  font-size: 1.2em;
  line-height: 1.55em;
}
md-switch {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.md-bb-toolbar-button {
  min-width: 40px;
  padding: 0px 12px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}
md-toolbar {
  min-height: 56px;
}
md-toolbar .md-toolbar-tools {
  height: 56px;
  max-height: 56px;
}
md-toolbar .md-toolbar-tools .md-button.md-bb-toolbar-button.md-btn-grp {
  margin-left: 2px;
  margin-right: 2px;
}
table.material {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
}
table.material thead {
  color: rgba(0, 0, 0, 0.6);
}
table.material tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.material tr td,
table.material tr th {
  padding: 10px 8px;
  display: table-cell;
  vertical-align: middle;
  border-radius: 0 !important;
}
table.material tr td .md-icon-button,
table.material tr th .md-icon-button {
  background-color: #fff;
}
table.material tr td:first-of-type,
table.material tr th:first-of-type {
  padding-left: 12px !important;
}
table.material tr td:last-of-type,
table.material tr th:last-of-type {
  padding-right: 12px !important;
}
table.material tr:first-of-type th {
  padding-top: 8px !important;
}
table.material tr.success,
table.material tr.danger,
table.material tr.warning {
  color: #FAFAFA !important;
}
table.material tr.success .md-icon-button,
table.material tr.danger .md-icon-button,
table.material tr.warning .md-icon-button {
  color: #666;
}
table.material tr.success .md-icon-button:hover,
table.material tr.danger .md-icon-button:hover,
table.material tr.warning .md-icon-button:hover {
  color: #fff;
}
table.material tr.success {
  background-color: #00C069 !important;
}
table.material tr.danger {
  background-color: rgba(244, 67, 54, 0.8) !important;
}
table.material tr.warning {
  background-color: rgba(255, 152, 0, 0.8) !important;
}
table.material tr.v-top td,
table.material tr.v-top th {
  vertical-align: top;
}
table.material tr:nth-child(even) {
  background-color: #fafafa;
}
table.material.smaller tr td,
table.material.smaller tr th {
  padding: 5px 2px;
}
table.material tr td md-checkbox {
  margin-bottom: 0;
}
table.material md-chips-wrap {
  box-shadow: none;
}
md-dialog md-select {
  margin-bottom: 24px;
}
ul.nested-list {
  list-style: none;
  padding-left: 0;
}
ul.nested-list * {
  outline: none !important;
}
ul.nested-list li li {
  padding-left: 100em;
  margin-left: -99em;
}
ul.nested-list li li:last-child {
  border-bottom: 0;
}
.nested-list-item {
  min-height: 20px;
  line-height: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 4px;
}
.nested-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.nested-list-item.active {
  border-left: 5px solid #00C069;
}
nested-list {
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  border-bottom: none;
}
nested-list * {
  outline: none !important;
}
nested-list nested-list {
  border: none;
  max-height: 100%;
}
nested-list.collapsed {
  display: none;
}
.filter-col md-card-title {
  padding: 10px 20px;
}
.filter-col md-input-container label {
  margin-bottom: 0;
}
.filter-col .md-title {
  font-size: 18px;
}
.filter-col md-card-content md-input-container.md-block {
  padding-bottom: 10px;
}
md-input-container {
  margin-bottom: 0;
}
md-input-container label {
  bottom: 54px;
  margin-bottom: 0;
}
.md-dialog-container {
  z-index: 1300;
}
.stripe-input {
  margin-right: 10px;
}
md-dialog.md-danger-theme {
  background: #f44336 linear-gradient(to right, #f44336, #f44336);
  color: #fff;
}
md-dialog.md-danger-theme .md-button {
  color: #fff;
}
md-dialog.md-danger-theme .md-dialog-content:after {
  content: '\f12a';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
  font-style: normal;
  font-size: 3em;
  position: fixed;
  right: 20px;
  opacity: 0.5;
  top: 3px;
}
md-dialog.md-warn-theme {
  background: #F57C00 linear-gradient(to right, #F57C00, #F57C00);
  color: #fff;
}
md-dialog.md-warn-theme .md-button {
  color: #fff;
}
md-dialog.md-warn-theme .md-dialog-content:after {
  content: '\f071';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
  font-style: normal;
  font-size: 3em;
  position: fixed;
  right: 20px;
  opacity: 0.5;
  top: 3px;
}
.settings-buttons .md-raised {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 15px;
  height: 140px;
  white-space: normal;
}
.settings-buttons .md-raised p {
  color: rgba(0, 0, 0, 0.6);
  text-transform: initial;
  line-height: normal;
}
md-chips.bb-md-chips-noborder,
md-chips.bb-md-chips-noborder .md-chips {
  box-shadow: none !important;
  padding: 0 !important;
}
.md-chips md-chip.smaller {
  height: 21px;
  line-height: 22px;
  margin: 0;
  font-size: 12px;
  padding: 0 6px 0 6px;
}
md-chip.bb-danger,
.bb-danger-bg {
  background-color: #f44336 !important;
  color: #fff !important;
}
md-chip.bb-success,
.bb-success-bg {
  background-color: #00C069 !important;
  color: #fff !important;
}
md-chip.bb-warn,
.bb-warn-bg {
  background-color: #F57C00 !important;
  color: #fff !important;
}
md-chip.bb-info,
.bb-info-bg {
  background-color: #0069C0 !important;
  color: #fff !important;
}
.bb-danger-fg {
  background-color: 'inherit' !important;
  color: #f44336 !important;
}
.bb-success-fg {
  background-color: 'inherit' !important;
  color: #00C069 !important;
}
.bb-warn-fg {
  background-color: 'inherit' !important;
  color: #F57C00 !important;
}
.bb-info-fg {
  background-color: 'inherit' !important;
  color: #0069C0 !important;
}
.md-select-menu-container.md-active {
  z-index: 1310;
}
.bb-step-progress .progress-holder {
  box-sizing: border-box;
  padding-top: 18px;
  padding-left: 8px;
  padding-right: 8px;
}
.bb-step-progress md-progress-linear .md-container.md-mode-determinate {
  background-color: rgba(0, 192, 105, 0.2);
}
.highlight {
  font-weight: 600;
  color: #00C069 !important;
}
.md-toolbar-tools .md-button.md-bb-toolbar-button.md-btn-grp {
  margin-left: 2px;
  margin-right: 2px;
}
.bb-rule-actions md-card-title,
.bb-rule-conditions md-card-title {
  padding-top: 5px;
  padding-bottom: 5px;
}
md-input-container > .md-icon-button,
md-input-container > bb-input-append {
  margin-top: 10px !important;
  position: absolute;
  right: -8px;
  top: -14px;
}
.h54 md-input-container {
  height: 54px !important;
}
.md-dialog-content > .meta-card {
  margin: -4px -4px auto;
}
md-card a,
.meta-card a {
  color: #00C069 !important;
}
.md-panel-outer-wrapper {
  z-index: 1400 !important;
}
md-tab-content {
  overflow: visible !important;
}
.md-button.md-icon-button {
  min-width: 40px !important;
}
.md-block {
  margin-bottom: 0;
}
md-card-content.colapsed {
  display: none;
}
md-tooltip {
  font-size: 14px !important;
}
.toggler {
  color: #666;
  font-size: 70%;
  margin-top: 4px;
}
md-card-title-text,
md-card-title-text * {
  outline: none !important;
}
.md-datepicker-calendar-pane.md-pane-open {
  z-index: 1300;
}
md-input-container input.date-range-picker {
  color: black !important;
  padding: 4.5px;
  border-bottom: 1px solid #ddd;
  border-top: none;
  border-left: none;
  border-right: none;
}
.dragover {
  background-color: #00C069;
}
.md-button.md-icon-button {
  min-width: 40px !important;
}
md-toolbar md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
md-toolbar md-input-container {
  margin-top: 22px;
}
.md-block {
  margin-bottom: 0;
}
md-card-content.colapsed {
  display: none;
}
.toggler {
  color: #666;
  font-size: 70%;
  margin-top: 4px;
}
.toggler.fa-filter {
  margin-right: 4px;
  color: #00C069;
}
md-card-title-text,
md-card-title-text * {
  outline: none !important;
}
.h54 md-input-container {
  height: 54px !important;
}
.md-subheader {
  background-color: #f0f0f0;
}
md-input-container md-progress-circular {
  position: absolute;
  right: 8px;
  top: 7px;
}
.md-virtual-repeat-container {
  z-index: 1300 !important;
}
.mat-button-wrapper > mat-icon,
.mat-button-wrapper > i {
  margin-top: -4px;
}
.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}
.md-chip-input-container input {
  min-width: 260px;
}
.no-error-spacer .md-errors-spacer {
  display: none;
}
.bb-old-card-content {
  background-color: #fff;
}
.md-primary.md-raised.md-button {
  color: #fff !important;
}
.bb-grey-toolbar + div {
  background-color: #fff;
}
/* bootstrap -> material */
.dropdown-menu,
.popover,
.popover,
.popover .arrow:after {
  border: none !important;
}
input.form-control,
select,
textarea,
.add-on,
.dropdown-menu,
.popover,
.pagination,
.pagination *,
.modal-footer,
.modal-header {
  border-radius: 0 !important;
}
.search-query,
.btn,
.modal,
.dialog {
  border-radius: 2px !important;
}
.btn,
.dropdown-toggle {
  background: #fff;
  box-shadow: unset;
}
.btn-danger {
  color: #fff;
  background: #f55;
}
.btn-success {
  background-color: #00c069;
  color: #fff;
  border: none;
}
.nav-tabs {
  border-bottom: 1px solid #f1f1f1;
  background-color: #fff;
}
.nav-tabs > li > a {
  border-radius: 0;
  color: #333;
  position: relative;
  text-transform: uppercase;
  padding: 12px 24px;
  border: none !important;
}
.nav-tabs > li > a:after {
  width: 0;
  height: 2px;
  background-color: #00c069;
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 50%;
  margin-right: 50%;
  bottom: -1px;
  transition: margin ease-in-out 120ms, width ease-in-out 120ms;
}
.nav-tabs > .active > a:after {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  transition: margin ease-in-out 120ms, width ease-in-out 120ms;
}
#dialogContent,
.modal-content {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}
form {
  margin-bottom: unset;
}
input.form-control,
.pagination *,
textarea.form-control,
select.form-control {
  box-shadow: none !important;
}
.btn,
input.form-control,
textarea.form-control,
select.form-control,
.add-on {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.dropdown-menu {
  padding: 0;
}
.pull-out .md-nav-bar {
  margin-bottom: 16px;
}
.errors {
  font-size: 12px;
  color: #dd2c00;
  margin-top: 10px;
}
md-toast .md-toast-content md-content {
  background-color: transparent;
  padding: 6px;
}
md-toast .md-toast-content md-content a,
md-toast .md-toast-content md-content a:visited,
md-toast .md-toast-content md-content a:active,
md-toast .md-toast-content md-content a:hover {
  color: white;
}
md-toast.md-success-toast-theme .md-toast-content {
  background-color: rgba(0, 191, 105, 0.9);
}
md-toast.md-info-toast-theme .md-toast-content {
  background-color: rgba(221, 221, 221, 0.9);
}
md-toast.md-error-toast-theme .md-toast-content {
  background-color: rgba(255, 69, 69, 0.9);
}
md-toast.md-warning-toast-theme .md-toast-content {
  background-color: rgba(255, 144, 13, 0.9);
}
md-toast .md-toast-content {
  padding: 0 2px;
}
.resource-editor-icon,
.resource-editor-icon:hover,
.resource-editor-icon:visited {
  position: absolute;
  display: inline;
  height: 13px;
  width: 13px;
  text-decoration: none;
  z-index: 999999;
  opacity: 0.35;
  margin: -14px 0 0 -2px;
  cursor: pointer;
}
.resource-editor-icon:hover {
  opacity: 1;
}
.resource-editor-iconbefore {
  font-family: 'FontAwesome';
  content: "\f024";
  /* flag */
  font-size: 9pt;
  color: red;
}
.resource-editor-button {
  z-index: 999999;
  color: white;
  background-color: DarkGreen;
  opacity: 0.35;
  position: fixed;
  bottom: 10px;
  right: 20px;
  padding: 7px 9px 5px 10px;
  border-radius: 50%;
}
.resource-editor-button.off {
  background-color: #b64545;
}
.resource-editor-button:before {
  font-family: 'FontAwesome';
  content: "\f024";
  /* flag */
  font-size: 14pt;
}
.resource-editor-button:hover {
  font-family: 'FontAwesome';
  opacity: 0.65;
}
/* Items kein overflow*/
.angular-ui-tree-node a {
  overflow-x: hidden;
}
/* angular tree view */
.tree-node {
  border: 1px solid #dae2ea;
  background: #f8faff;
  color: #7c9eb2;
}
.tree-node-content {
  margin: 2px;
}
.angular-ui-tree-handle {
  font-weight: normal !important;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*hide ng-cloak elements quicker*/
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
/* textAngular */
.ta-editor {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.ta-scroll-window > .ta-bind {
  min-height: 140px !important;
}
.ta-editor.ta-html,
.ta-scroll-window.form-control {
  min-height: 140px !important;
}
[ta-button="ta-button"] {
  min-width: 40px !important;
}
#toolbarWC,
#toolbarCC {
  float: right;
  display: inline-block;
}
.dev-sys-warning {
  background-color: red !important;
  color: white !important;
  padding: 8px;
}
.dev-sys-note {
  background-color: orange !important;
  color: white !important;
  padding: 8px;
}
/* Legacy Styles! */
@media only screen and (max-width: 766px) {
  .collapsing,
  .in {
    position: absolute;
    right: 20px;
    max-width: 400px;
    background-color: #f7f7f7;
  }
  .collapsing ul li a,
  .in ul li a {
    color: #555 !important;
  }
}
/* Schmale Seite: Menu mit Icons statt Text */
@media (min-width: 0) {
  .menuEntrySm {
    display: none;
  }
  .menuEntryLg {
    display: initial;
  }
}
@media (min-width: 768px) {
  .menuEntrySm {
    display: initial;
  }
  .menuEntryLg {
    display: none;
  }
}
@media (min-width: 1001px) {
  .menuEntrySm {
    display: none;
  }
  .menuEntryLg {
    display: initial;
  }
}
/* suchform bei schmalen seiten ausblenden */
@media (max-width: 1000px) {
  .navbar-search {
    display: none;
  }
}
