$swift-ease-out-duration: 0.16s;
$swift-ease-in-duration: 0.16s;
$swift-ease-in-out-duration: 0.16s;
$material-enter-duration: 0.16s;
$material-leave-duration: 0.16s;

@import "angular-material/angular-material.scss";

.md-select-menu-container {

  // enter: md-select scales in, then options fade in.
  &.md-active {
    display: block;
    opacity: 1;
    md-select-menu {
      transition: unset;
      transition-duration: 0s;
      > * {
        opacity: 1;
        transition: unset;
        transition-duration: 0s;
        transition-delay: 0s;
      }
    }
  }
  
  // leave: the container fades out
  &.md-leave {
    opacity: 0;
    transition: unset;
    transition-duration: 0s;
  }
}