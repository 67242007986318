// ---------------------------------------
// ALLGEMEINE HTML TAGS
// ---------------------------------------

html, body {
    height: 100%;
    background-color: unset;
}

body {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
    font-size: 14px;
    /* padding-top: 30px; */
    /* padding-bottom: 40px; */

    &.start {
        .thumbnail {
            padding-top: 20px;
            background-color: #fff;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            -webkit-box-shadow: #999 0 0 9px;
            -moz-box-shadow: #999 0 0 9px;
            box-shadow: #999 0 0 9px;
            color: #666;

            .fa-group, .fa-cogs, .fa-money {
                font-size: 2em;
                color: #CF003E;
            }
        }

        #youtube {
            padding-top: 0;
            border-color: #000;
            background-color: #000;

            iframe {
                border: 0;
                max-width: 100%;
            }
        }

        .caption {
            padding: 0 15px 10px 15px;
        }

        article, .article {
            margin-top: 30px;
            margin-bottom: 90px;
            padding: 10px 20px;
        }
    }

    &.background {
        padding-top: 0;
        padding-bottom: 0;

        header {
            position: fixed;
            top: 0;
            left: 0;
            padding-top: 0;
            padding-bottom: 10px;
            height: 100px;
            width: 100%;
            z-index: 1;
        }

        #logoLink {
            right: 50px;
            top: 15px;
            position: fixed;
            z-index: 1;
        }

        .navbar {
            padding-top: 35px;
            padding-bottom: 10px;
            max-width: 700px;
            margin-left: 50px;
        }

        #mainContent {
            margin-top: 130px;
        }
    }
}

/* very small form */
form.form-tiny {
    .form-group {
        margin-bottom: 4px;

        .checkbox {
            margin-bottom: 4px;
            margin-top: 4px;
        }
    }

    fieldset legend {
        margin-bottom: 4px;
        font-size: 18px;
    }
}

footer {
  font-size: 85%;
  width: 98%;
  padding-top: 10px;
  margin: 20px auto 0 auto;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  color: #777;
  overflow-x: hidden;

  a {
    color: #777 !important;
  }
}

a {
    color: #009f49;
    text-decoration: none;

    &:hover {
        color: #009f49;
        text-decoration: underline;
    }
}

label {
    font-weight: normal;
}

/* In Tabellen sitzt die Auswahlcheckbox sonst zu tief */
tbody input[type="checkbox"] {
    margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h1 {
    font-weight: 300;
    font-style: normal;
}

// ---------------------------------------
// TABLES
// ---------------------------------------

.table {
    thead th {
        color: #111;
        background-color: #f6f6f6;
        font-weight: normal;
        white-space: nowrap;

        .sortlink {
            cursor: pointer;
        }
    }

    td p {
        padding-top: 8px;
    }
}

/* bei Inputs in kleinen Tabellen das Riesenmargin entfernen */
.table-condensed input, .table-condensed select, .table-condensed .input-append {
    margin-bottom: 0;
}

// ---------------------------------------
// HIGHLIGHTING VON UNTERSCHIEDEN BEI ARTIKELIMPORT / ZAHLUNGSIMPORT
// ---------------------------------------

.diffHighlightPositive {
    background-color: #ECFFF6;
}

.diffHighlightNegative {
    background-color: #FFADC2;
}

.partialPayment {
    background-color: #FFe2a2;
}

/* UI SELECT for Logo Selection */
.logo-select {
    .ui-select-toggle {
        height: 60px;
    }

    img {
        max-height: 50px;
    }
}

/* Display of fields in a address panel */
.addr-panel-field {
    background-color: #e5e5e5;
    margin-right: 2px;
    padding: 1px 2px;
}

.shipping-svc-panel {
    margin-bottom: 3px;

    .panel-heading {
        padding: 4px 10px;
    }

    .panel-body {
        padding: 4px 10px;
    }
}

.shipping-svc-panel-alert {
    background-color: #ffbbbb;
}

.shipping-svc-panel-alert .panel-heading {
    background-color: #ff8888;
}

.shipping-svc-panel-auto {
    background-color: #99ccff;
}

.shipping-svc-panel-auto .panel-heading {
    background-color: #3399ff;
}

/* icons in text boxen */
i.form-control-feedback {
    right: 40px;
    top: 9px;
    color: @primary;
    font-size: 16px;
    pointer-events: initial;
    opacity: 0.6;
    height: auto;
    z-index: 0;
}

/* icons neben checkboxen*/
i.form-control-feedback-checkbox {
    color: @primary;
    position: relative;
    font-size: 16px;
    pointer-events: initial;
    opacity: 0.6;
    height: auto;
}

button i.form-control-feedback {
    right: 2px;
    top: 2px;
    color: @primary;
    width: 12px;
    font-size: 12px;
    pointer-events: initial;
    opacity: 0.6;
    height: auto;
}

/* icons in infoboxen / Fehlermeldungen groß und verital zentriert*/
.icon-msg-box {
    font-size: 30px;
    padding-right: 10px;
    vertical-align: middle;
}

/* inline Checkbox in BulkEdit Formularen*/
input[type="checkbox"].checkbox-bulkedit {
    margin-top: 10px;
}

/* inline edit, damit der edit Button auch bei leeren Feldern positioniert wird*/
.inline-edit-container {
    position: relative;
    height: 100%;
}

#ajaxLoaderNavAngular {
    color: @primary;
}

li.activeNav {
    border-bottom: 3px solid @primary;
    height: 39px;
}

.btn-tiny {
    font-size: 8px;
    height: 16px;
    padding: 2px 5px;
    border-radius: 3px;
}

.progress-billbee .bar, .progress .bar-success {
    background-color: #9DBB1A;
    background-image: -moz-linear-gradient(top, @primary, @primary);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9DBB1A), to(#CEE07F));
    background-image: -webkit-linear-gradient(top, #9DBB1A, #CEE07F);
    background-image: -o-linear-gradient(top, #9DBB1A, #CEE07F);
    background-image: linear-gradient(to bottom, #9DBB1A, #CEE07F);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9DBB1A', endColorstr='#CEE07F', GradientType=0);
}

.progress-billbee.progress-striped .bar, .progress-striped .bar-success {
    background-color: @primary;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

#dialogContent, .dialog {
    border: 1px solid #ddd;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.ruleContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    margin-bottom: 5px;
    background-color: #EFF5D4;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: solid 1px #009f49;
}

.editLinearTabContainer {
    h4 {
        background-color: #ECFFF6;
        font-weight: bold;
        padding: 4px;
    }
    /* von Order.Edit imt Linear Modus benutzt */
    > div.tab-pane {
        padding: 0 10px 5px 10px;
        margin-bottom: 10px;
        background-color: #EFF5D4;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: solid 1px @primary;
    }
}

.ulArrow, .ulCheck {
    list-style: none;
    margin-left: 15px;
}

.ulArrow li:before {
    font-family: 'FontAwesome', serif;
    content: '\f101';
    margin: 0 5px 0 -15px;
    color: #CF003E;
}

.ulCheck li:before {
    font-family: 'FontAwesome', serif;
    content: '\f046';
    margin: 0 5px 0 -15px;
    color: @primary;
}

/* Order.Edit etwas sparsamer mit dem Platz umgehen */
#details.tab-pane {
    td {
        padding: 2px;
    }

    .input-append .add-on:last-child, .input-append .btn:last-child, input, select, .combobox-container .add-on {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

/* Ansicht dropdown Seite Bestellungen: Checkboxen etwas stauchen */
#visibleColumns label.checkbox {
    margin-bottom: 0;
}

a.historyLink {
    text-decoration: none;
    color: black;

    &:hover {
        color: black;
    }
}

/* Regios fontPicker: Dropdown über input boxen legen*/
.fontbox {
    z-index: 1;
}

.centeredWithHorizontalLine {
    width: 100%;
    display: inline-block;
    text-align: center;
    border-bottom: 1px solid #ccc;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.centeredWithHorizontalLineText {
    padding: 0 10px;
    background-color: white;
    color: black;
}

.orderGridBody ul {
    margin-left: 0;

    li {
        list-style: none;

        i {
            color: #ccc;
            font-size: 60%;
            vertical-align: middle;
        }
    }
}

/* Kleines grünes Hilfe Icon in Dialogen neben dem Close x*/
.titleHelpIcon {
    color: @primary;
    margin-right: 4px;
    -o-transition: color .1s ease-out, background 1s ease-in;
    -ms-transition: color .1s ease-out, background 1s ease-in;
    -moz-transition: color .1s ease-out, background 1s ease-in;
    -webkit-transition: color .1s ease-out, background 1s ease-in;
    transition: color .1s ease-out, background 1s ease-in;
    font-size: 200%;
    position: absolute;
    right: 10px;
    top: 45px;

    &:hover {
        color: #40df89;
        text-decoration: none;
    }
}

/* inline edit elemente mit pos=absolute über die Zelle rendern
   Parent muss pos=relative haben. Bei Firefox klappts bei TD aber nicht
   Deshalb muss das Input mit einem pos=rel div gewrappt werden
*/

.inlineeditInput {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
}

/* Order.ArticleInfo */
td.articleData i.fa-info-circle:hover {
    cursor: pointer;
}

.paragraph {
    margin-bottom: 10px;
}

.colorError {
    color: #f55;
}

.colorSuccess {
    color: @primary;
}

/* Highlighting von Unterschieden bei Artikelimport*/

/* Anzeige der StatusIcons bei Order */
i.status-icon-disabled {
    color: #ddd;
}

i.status-icon-enabled {
    color: @primary;
}

/* Große Schaltflächen mit Überschrift und Icon (z.B. auf der Seite Einstellungen) */
button.bigButtonLink {
    text-decoration: none;
    color: inherit;
    box-shadow: #999 0 0 9px;
    text-align: left;
    min-height: 150px !important;
    padding: 15px;
    margin-top: 10px;
    white-space: normal;
}

/* Make the modal face animation a bit faster */
.fade {
    opacity: 0;
    -webkit-transition: opacity 0.1s linear;
    -moz-transition: opacity 0.1s linear;
    -ms-transition: opacity 0.1s linear;
    -o-transition: opacity 0.1s linear;
    transition: opacity 0.1s linear;
}

/* Suchfeld */
input.search-query {
    width: 120px;
}

.filter-close {
    float: none;
    position: relative;
    left: 145px;
    top: 7px;
    z-index: 3;
    width: 10px;
    height: 10px;
}

.debug {
    background: #ffff00;
}

.debug2 {
    background: #ff0000;
}

/* Tooltips in Modals nach oben holen*/
.tooltip {
    z-index: 2000 !important;
}

/* popover etwas breiter machen */
.popover {
    width: 280px;
    z-index: 2000 !important;

    table {
        font-size: 80%;
        margin-bottom: 2px;
    }
}

#notificationMenu {
    font-weight: 300;

    &:hover {
        font-weight: 700;
    }
}

/* inline edit / info */
td.inlineedit-highlight, tr.inlineedit-highlight td,
td.inlineinfo-highlight, tr.inlininfo-highlight td {
    background-color: @primary !important;
}

/* typeahead in modals */
.typeahead {
    z-index: 1100;
}

/* affix */
#affixFixed.affix {
    top: 40px;
    width: 100%;
    background-color: white;
    z-index: 1;
}

.affix #pageSizeInfo {
    margin-right: 40px;
}

// ---------------------------------------
// BUTTONGROUP
// ---------------------------------------

#actionbuttons {
    margin-bottom: 10px;
}

div#actionbuttons i {
    font-size: 16px;
}

a.sortlink {
    cursor: pointer;
}

/* Bilderanzeige Order.Edit */
span.detailImg {
    position: relative;
}

.detailImgZoom {
    position: absolute;
    max-width: inherit;
    z-index: 1000;
}

.pickadate-modal {
    position: fixed !important;
}

.setup-item-status {
    .fa {
        padding: 0 !important;
        font-size: 19px !important;
    }

    .fa-check {
        color: @primary !important;
    }
}

.fullwidth {
    width: 100% !important;
}

.fa-expand {
    cursor: pointer;
}

.tab-pane-section {
    padding: 0 25px;
}

.stockName {
    background-color: #eee;
    padding: 4px;
    text-align: center;
    font-weight: bold;
}

i {
    &.primary {
        color: @primary;
    }

    &.fa-1_5x {
        font-size: 1.5em;
    }
}

bb-split-button {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-right: 8px !important;
    margin-left: 8px !important;
    border-radius: 2px;

    .md-button {
        box-shadow: none !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        border-radius: 0;
    }

    .md-button:not(:first-child), md-menu > .md-button {
        border-left: 1px solid rgba(0,0,0,0.125) !important;
    }
}

.bb-has-counter {
    overflow: visible;
}

.bb-counter {
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 17px;
    border: 1px solid darken(@primary, 5%);
    color: #fff;
    height: 17px;
    z-index: 999;
    font-size: 11px;
    font-weight: bold;
    vertical-align: top;
    line-height: 9px;
    border-radius: 10px;
    padding: 3px;
    background: @primary;
    //background: linear-gradient(180deg, lighten(@primary, 4%), @primary);
}

// UI Select Remove button, to prevent interfering with hint tag #161376905
.ui-select-bootstrap .ui-select-toggle {
        .btn {
            right: 29px !important;
        }
}

.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu {
    position: relative;
    &:hover {
        > ul.dropdown-menu {
            display: block;
        }
    }
    .dropdown-menu {
        top: 0;
        right: 100%;
        margin-top: -1px;
    }
}
.ta-editor {
    width: 100%;
}

.drag-drop-list-item {
  cursor: move;
  min-height: 60px;
  height: auto !important;
  background: white;
  overflow: hidden;
  display: block;
}

.drag-drop-list-item .mat-list-item-content {
  border-bottom: 1px solid #ddd !important;
  padding: 10px 16px !important;
  height: auto !important;
}

// region animated bell
/* BELL */

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    
    10% {
        transform: rotate(-15deg);
    }

    30% {
        transform: rotate(15deg);
    }
    
    50% {
        transform: rotate(-15deg);
    }

    70% {
        transform: rotate(15deg);
    }

    90% {
        transform: rotate(0deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(359deg);
    }
}

.mat-toolbar, .loading-indicator {
    .fa-bell-on, .fa-bell-exclamation {
        animation: ring 1s ease 1;
        transform-origin: center top;
    }
    .fa-sync-alt {
        animation: rotate 2s ease infinite;
        width: 1.25em;
        height: 1.25em;
        text-align: center;
    }
}

/* for checkboxes in amazon shop tpl */
.checkbox-margin-right {
    margin-right: 20px !important;
}
//endregion

/* Ansicht: Versandstation */
.cardTitleWithActionIcon {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    > .fa-close {
        margin: 0;
        cursor: pointer;
    }
}