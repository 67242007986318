md-slider-container md-input-container input[type="number"] {
  min-width: 55px;
}

.md-progress-linear-absolute {
  position: absolute;
  z-index: 1000;
}

.bg-color-error {
  background-color: #f55 !important;
}

// align head with cards
.aligned-content {
  margin: 0 -8px;
}

md-button {
  &.groupX {
    font-size: 16px;
    margin: 20px 0 !important;
    padding: 3px 15px 3px 15px !important;
    color: rgb(49, 46, 46);
    background-color: rgba(224, 224, 224, 0.96);
    text-transform: none;
    font-weight: 400;
    min-width: 100px;
  }

  &.middle {
    border-radius: 0;
    border-left: 1px solid rgba(230, 230, 230, 0.96);
    border-right: 1px solid rgba(230, 230, 230, 0.96);
  }

  &:not([disabled]):hover {
    background-color: rgba(193, 193, 193, 0.96);
    color: rgba(44, 65, 164, 0.96);
    transition: 0.3s;
  }
}

.bb-grey-toolbar {
  background-color: #eee !important;
  color: #333 !important;
}

.bb-white-toolbar {
  background-color: #fff !important;
  color: #333 !important;
}

.md-icon-button i.fa {
  font-size: 1.2em;
  line-height: 1.55em;
}

md-switch {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// Toolbar

.md-bb-toolbar-button {
  min-width: 40px;
  padding: 0px 12px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

md-toolbar {
  min-height: 56px;

  .md-toolbar-tools {
    height: 56px;
    max-height: 56px;

    .md-button.md-bb-toolbar-button.md-btn-grp {
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}

// Material HTML Table
table.material {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;

  thead {
    color: rgba(0, 0, 0, 0.6);
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    td, th {
      padding: 10px 8px;
      display: table-cell;
      vertical-align: middle;
      border-radius: 0 !important;

      .md-icon-button {
        background-color: #fff;
      }
      &:first-of-type {
        padding-left: 12px !important;
      }

      &:last-of-type {
        padding-right: 12px !important;
      }
    }

    &:first-of-type th {
      padding-top: 8px !important;
    }

    &.success, &.danger, &.warning {
      color: #FAFAFA !important;
      .md-icon-button {
        color: #666;
        &:hover {
          color: #fff;
        }
      }

    }
    &.success {
      background-color: @primary !important;
    }

    &.danger {
      background-color: fade(#F44336, 80%) !important;
    }

    &.warning {
      background-color: fade(#FF9800, 80%) !important;
    }

    &.v-top {
      td, th {
        vertical-align: top;
      }
    }
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }

  &.smaller {
    tr {
      td, th {
        padding: 5px 2px;
      }
    }
  }

  tr td md-checkbox {
    margin-bottom: 0;
  }

  md-chips-wrap {
    box-shadow: none;
  }
}

md-dialog md-select {
  margin-bottom: 24px;
}

// Nested list
ul.nested-list {
  list-style: none;
  padding-left: 0;

  * {
    outline: none !important;
  }

  li {
    li {
      padding-left: 100em;
      margin-left: -99em;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.nested-list-item {
  min-height: 20px;
  line-height: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }

  &.active {
    border-left: 5px solid @primary;
  }
}

nested-list {
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  border-bottom: none;

  * {
    outline: none !important;
  }

  nested-list {
    border: none;
    max-height: 100%;
  }

  &.collapsed {
    display: none;
  }
}

// make filter column left of table a bit more condensed
.filter-col {
  md-card-title {
    padding: 10px 20px;
  }

  md-input-container label {
    margin-bottom: 0;
  }

  .md-title {
    font-size: 18px;
  }

  md-card-content md-input-container.md-block {
    padding-bottom: 10px;
  }
}

md-input-container {
  margin-bottom: 0;
  label {
    bottom: 54px;
    margin-bottom: 0;
  }
}

.md-dialog-container {
  z-index: 1300;
}

// Stripe Inputs
.stripe-input {
  margin-right: 10px;
}

.dialogTheme(@fromColor; @toColor; @icon) {
  background: @fromColor linear-gradient(to right, @fromColor, @toColor);
  color: #fff;
  .md-button {
    color: #fff;
  }
  .md-dialog-content:after {
    content: @icon;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    font-style: normal;
    font-size: 3em;
    position: fixed;
    right: 20px;
    opacity: 0.5;
    top: 3px;
  }
}

md-dialog.md-danger-theme {
  .dialogTheme(@danger, @danger, '\f12a');
}

md-dialog.md-warn-theme {
  .dialogTheme(@warn, @warn, '\f071');
}

// settings main page
.settings-buttons {
  .md-raised {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 15px;
    height: 140px;
    white-space: normal;

    p {
      color: rgba(0, 0, 0, 0.6);
      text-transform: initial;
      line-height: normal;
    }
  }
}

md-chips.bb-md-chips-noborder, md-chips.bb-md-chips-noborder .md-chips {
  box-shadow: none !important;
  padding: 0 !important;
}

.md-chips md-chip.smaller {
  height: 21px;
  line-height: 22px;
  margin: 0;
  font-size: 12px;
  padding: 0 6px 0 6px;
}

.force-color(@bgColor; @fgColor: 'inherit') {
  background-color: @bgColor !important;;
  color: @fgColor !important;
}

md-chip.bb-danger, .bb-danger-bg {
  .force-color(@danger, #fff);
}

md-chip.bb-success, .bb-success-bg {
  .force-color(@primary, #fff);
}

md-chip.bb-warn, .bb-warn-bg {
  .force-color(@warn, #fff);
}

md-chip.bb-info, .bb-info-bg {
  .force-color(@info, #fff);
}

.bb-danger-fg {
  .force-color('inherit', @danger);
}

.bb-success-fg {
  .force-color('inherit', @primary);
}

.bb-warn-fg {
  .force-color('inherit', @warn);
}

.bb-info-fg {
  .force-color('inherit', @info);
}

.md-select-menu-container.md-active {
  z-index: 1310;
}

.bb-step-progress {
  .progress-holder {
    box-sizing: border-box;
    padding-top: 18px;
    padding-left: 8px;
    padding-right: 8px;
  }
  md-progress-linear {
    .md-container.md-mode-determinate {
      background-color: fade(@primary, 20%);
    }
  }
}

// autocomplete highlight
.highlight {
  font-weight: 600;
  color: @primary !important;
}

.md-toolbar-tools .md-button.md-bb-toolbar-button.md-btn-grp {
  margin-left: 2px;
  margin-right: 2px;
}

// Rules Edit
.bb-rule-actions, .bb-rule-conditions {
  md-card-title {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

md-input-container > .md-icon-button, md-input-container > bb-input-append {
  margin-top: 10px !important;
  position: absolute;
  right: -8px;
  top: -14px;
}

.h54 md-input-container {
  height: 54px !important;
}

.md-dialog-content > .meta-card {
  margin: -4px -4px auto;
}

md-card, .meta-card {
   a {
     color: @primary !important;
   }
 }

.md-panel-outer-wrapper {
  z-index: 1400 !important;
}

md-tab-content { // #158383880 Dropdown am unteren Rand eines Tabs
  overflow: visible !important;
}
.md-button.md-icon-button {
  min-width: 40px !important;
}

.md-block {
  margin-bottom: 0;
}

md-card-content.colapsed {
  display: none;
}

md-tooltip {
  font-size: 14px !important;
}

.toggler {
  color: #666;
  font-size: 70%;
  margin-top: 4px;
}

md-card-title-text, md-card-title-text * {
  outline: none !important;
}

// Datepicker in a dialog
.md-datepicker-calendar-pane.md-pane-open {
  z-index: 1300;
}

md-input-container input.date-range-picker {
  color: black !important;
  padding: 4.5px;
  border-bottom: 1px solid #ddd;
  border-top: none;
  border-left: none;
  border-right: none;
}

.dragover {
  background-color: @primary;
}

// list view
.md-button.md-icon-button {
  min-width: 40px !important;
}

md-toolbar md-input-container .md-errors-spacer {
  min-height: 0px !important;
}

md-toolbar md-input-container {
  margin-top: 22px;
}

.md-block {
  margin-bottom: 0;
}

md-card-content.colapsed {
  display: none;
}

.toggler {
  color: #666;
  font-size: 70%;
  margin-top: 4px;
}

.toggler.fa-filter {
  margin-right: 4px;
  color: #00C069;
}

md-card-title-text, md-card-title-text * {
  outline: none !important;
}

.h54 md-input-container {
  height: 54px !important;
}

.md-subheader {
  background-color: rgb(240, 240, 240);
}

md-input-container md-progress-circular {
  position: absolute;
  right: 8px;
  top: 7px;
}

.md-virtual-repeat-container {
  z-index: 1300 !important;
}

/// Material NEU
.mat-button-wrapper > mat-icon, .mat-button-wrapper > i {
  margin-top: -4px;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}


.md-chip-input-container {
  input {
    min-width: 260px;
  }
}

.no-error-spacer .md-errors-spacer {
  display: none;
}

.bb-old-card-content {
  background-color: #fff;
}

.md-primary.md-raised.md-button {
  color: #fff !important;
}

.bb-grey-toolbar + div {
  background-color: #fff;
}

/* bootstrap -> material */

.dropdown-menu, .popover, .popover, .popover .arrow:after  {
  border: none !important;
}

input.form-control, select, textarea, .add-on, .dropdown-menu, .popover, .pagination, .pagination *, .modal-footer, .modal-header {
  border-radius: 0 !important;
}
.search-query, .btn, .modal, .dialog {
  border-radius: 2px !important;
}
.btn, .dropdown-toggle {
  background: #fff;
  box-shadow: unset;
}
.btn-danger {
  color: #fff;
  background: #f55;
}
.btn-success {
  background-color: #00c069;
  color: #fff;
  border: none;
}
.nav-tabs {
  border-bottom: 1px solid #f1f1f1;
  background-color: #fff;
}
.nav-tabs>li>a {
  border-radius: 0;
  color: #333;
  position: relative;
  text-transform: uppercase;
  padding: 12px 24px;
  border: none !important;
}
.nav-tabs>li>a:after {
  width: 0;
  height: 2px;
  background-color: #00c069;
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 50%;
  margin-right: 50%;
  bottom: -1px;
  transition: margin ease-in-out 120ms, width ease-in-out 120ms;
}
.nav-tabs>.active>a:after {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  transition: margin ease-in-out 120ms, width ease-in-out 120ms;
}

#dialogContent, .modal-content {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0,0,0,.12) !important;
  border: none !important;
}
form {
  margin-bottom: unset;
}
input.form-control, .pagination *, textarea.form-control, select.form-control {
  box-shadow: none !important;
}

.btn, input.form-control, textarea.form-control, select.form-control, .add-on {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.dropdown-menu {
  padding: 0;
}

.pull-out .md-nav-bar {
  margin-bottom: 16px;
}

.errors {
    font-size: 12px;
    color: #dd2c00;
    margin-top: 10px;
}
