/* Validation Errors*/
.validation-summary-errors {
  font-weight: bold;
  ul {
    list-style-type: none;
  }
}

/* Tabheader hervorheben, wenn das Tab einen Validationfehler enthält */
.tabHeaderValidationError {
  color: #CF003E !important;
  font-weight: bold;
}

/* ShowErrors validation: Message ohne error ausblenden*/
.form-group .help-block {
  display: none;
}

.form-group.has-error .help-block {
  display: block;
}


.form-control-alert {
    color: #660000 !important;
}

.label-auto {
    background-color: #3399ff;
}

.label-alert {
    background-color: #ff8888;
}


/* ui bootstrap datepicker */

.datepicker + ul {
  padding: 6px;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: @primary;
  color: #fff;
  cursor: pointer;
}

.tab-content {
  margin-top: 10px;
}

/* Forms kleiner */
.form-group {
  margin-bottom: 10px;
}

/* Pagination ui-bootstrap*/
.pagination {
  > .active {
    > a, .pagination > .active > span,
    > a:hover,
    > span:hover,
    > a:focus,
    > span:focus {
      background-color: #CF003E;
      border-color: #CF003E;
    }
  }
  > li > a {
    color: #888;
    text-decoration: none;
    &, > span {
      padding: 3px 8px;
    }
  }

  /* Pagination gleich hoch machen */
  ul > li > a {
    height: 20px;
  }
}

.navbar {
  height: 40px;
  min-height: 40px;
}

.navbar-brand {
  height: 40px;
}

.navbar-form {
  padding: 0 4px;
  margin-top: 2px;
  margin-bottom: 2px;

}

.btn-danger {
  background-color: #CF003E;
  background-image: -moz-linear-gradient(top, #FF5C85, #FF1144);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#FF5C85), to(#FF1144));
  background-image: -webkit-linear-gradient(top, #FF5C85, #FF1144);
  background-image: -o-linear-gradient(top, #FF5C85, #FF1144);
  background-image: linear-gradient(to bottom, #FF5C85, #FF1144);
  &:active {
    background-color: #FF1144;
    color: #ffffff;
  }

  &:hover {
    color: #ffffff;
    background-color: #FF1144;
  }

  &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #FF1144;
  }
}

.btn-group.open {
  .btn-danger.dropdown-toggle {
    background-color: #CF003E;
  }
}

.btn-success {
  background-color: #00a75b;
  border: 1px solid #999;

  &:active {
    &:focus {
      background-color: #00c069;
    }
    color: #ffffff;
    background-color: #00c069;
  }

  &:focus {
    background-color: #00c069;
  }

  &:hover {
    color: #ffffff;
    background-color: #00c069;
    *background-color: #00c069;
  }

  &.open {
    background-color: #00a75b;
    border: 1px solid #999;
  }

  &.active {
    &:focus {
      background-color: #00c069;
    }
    color: #ffffff;
    background-color: #00c069;
  }

  &.focus {
    background-color: #00c069;
  }

  &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #00c069;
  }
}

.alert {
  background-color: #d0e9c6;
  border: 1px solid #d0e9c6;
  color: #333;

  &.alert-success {
    background-color: #d0e9c6;
    border-color: #d0e9c6;
    color: #333;
  }

  &.alert-error {
    background-color: #FFADC2;
    border-color: #FFADC2;
    color: #333;
  }

  &.alert-info {
    background-color: #d0e9c6;
    border-color: #d0e9c6;
    color: #333;
  }
}

.nav-tabs li a {
  cursor: pointer;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 550px;
}

.navbar .nav > li > a {
  padding: 10px;
}

/*
DROPDOWN ON HOVER
*/

ul.nav li.dropdown:hover>ul.dropdown-menu {
  display: block;
}

a.menu:after, .dropdown-toggle:after {
  content: none;
}

/*
LEGENDS + FIELDSETS IN DROPDOWNS
*/

ul.dropdown-menu {
  form {
    margin: 0 0 0;
  }
  fieldset {
    margin: .5em 1em 1em 1em;
    padding: .5em;
    border: 1px solid #ccc;
  }

  legend {
    width: auto;
    padding: .5em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 0;
    color: #000;
    border: 0;
    font-weight: bold;
  }

  label {
    color: #000;
  }
}

div.dropdown-menu {
  padding: 10px 10px 0;

  input.remember {
    float: left;
    margin-right: 10px;
  }
}

/* blendet die legend bei Tab-Nav aus, da redundant, aber für Screenreader bleibt es sichtbar */
div.tab-pane legend {
  text-indent: -999em;
  height: 0;
  border: 0;
}

/* Popovers werden sonst abgeschnitten, wenn sie den Rand des Tabs überchreiten */
.tab-content {
  overflow: initial;
}

.colorpicker {
  z-index: 1061;
}

.label-important, .badge-important {
  background-color: #f55;
}

.label-success, .badge-success {
  background-color: #d0e9c6;
}

.label-success-dark, .badge-success-dark {
  background-color: @primary;
}

.table tbody tr.warning td {
  background-color: #FFD6E0;
}

.table.table-hover tbody tr.warning:hover td {
  background-color: #FFADC2;
}

.success, .table tbody tr.success td, .datagrid .group.success .col {
  background-color: #d0e9c6;
}

.table-hover tbody tr.success:hover td {
  background-color: #d0e9c6;
}

.table tbody tr.green td {
  background-color: #9feeac;
}

.table tbody tr.error td {
  background-color: #FF2969;
}

.has-error .form-control {
  border-color: #CF003E;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #CF003E;
}

.control-group.error > label, .control-group.error .help-block, .control-group.error .help-inline {
  color: #CF003E;
}

.form-item-error {
  font-size: 12px;  padding: 6px 0;
  color: red;
}

.ui-select-search.form-control {
  padding: 0 0 0 10px;
}


.ui-select-bootstrap .ui-select-toggle > a.btn {
  right: 24px;
}