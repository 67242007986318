/* Legacy Styles! */

@media only screen and (max-width: 766px) {
  .collapsing, .in {
    position: absolute;
    right: 20px;
    max-width: 400px;
    background-color: #f7f7f7;
    & ul li a {
      color: #555 !important;
    }
  }
}

/* Schmale Seite: Menu mit Icons statt Text */
@media (min-width: 0) {
  .menuEntrySm {
    display: none;
  }

  .menuEntryLg {
    display: initial;
  }
}

@media (min-width: 768px) {
  .menuEntrySm {
    display: initial;
  }

  .menuEntryLg {
    display: none;
  }
}

@media (min-width: 1001px) {
  .menuEntrySm {
    display: none;
  }

  .menuEntryLg {
    display: initial;
  }
}

/* suchform bei schmalen seiten ausblenden */
@media (max-width: 1000px) {
  .navbar-search {
    display: none;
  }
}
